import Vue from 'vue';
import VueRouter from 'vue-router';
import Devices from '../components/Devices.vue';
import ReBells from '../components/Bells.vue';
import Shares from '../components/Shares.vue';
import Home from '../components/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/devices',
    name: 'Devices',
    component: Devices,
  },
  {
    path: '/rebells',
    name: 'ReBells',
    component: ReBells,
  },
  {
    path: '/shares',
    name: 'Shares',
    component: Shares,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
