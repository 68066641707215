<template>
  <v-app id="inspireapp">
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <v-list dense>
        <v-list-item :key="'devices'"
         link to="/devices" v-on:click="drawer = false;">
          <v-list-item-action>
            <v-icon>mdi-cellphone-wireless</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Devices</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :key="'rebells'"
        link to="/rebells" v-on:click="drawer = false;">
          <v-list-item-action>
            <v-icon>mdi-bell</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>ReBells</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item :key="'shares'"
        link to="/shares" v-on:click="drawer = false;">
          <v-list-item-action>
            <v-icon>mdi-share-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Shares</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="blue darken-3" dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
     <!--  <router-link to="/" tag="div" class="v-toolbar__title"> -->
      <v-toolbar-title style="width: 300px; cursor: pointer;"
      class="ml-0 pl-4" @click="home">
        <span class="hidden-sm-and-down">ReBell Admin</span>
      </v-toolbar-title>
      <!-- </router-link> -->
      <v-spacer />

      <v-btn icon @click="setter();">
        <v-badge v-if="messageNum>0" color="red" :content="messageNum">
        <v-icon>mdi-message</v-icon>
        </v-badge>
        <v-icon v-else>mdi-message</v-icon>
      </v-btn>

      <v-menu offset-y open-on-hover>
        <template v-slot:activator="{ on }">
          <v-btn icon large v-on="on">
            <v-avatar>
              <v-icon dark>mdi-account-circle</v-icon>
            </v-avatar>
          </v-btn>
        </template>
        <v-list>
          <v-list-item :key="'user'">
            <v-list-item-title>{{user.displayName}}</v-list-item-title>
            <div><v-list-item-subtitle>  ({{user.email}})</v-list-item-subtitle></div>
          </v-list-item>
          <v-list-item :key="'logout'" v-on:click="signOut()">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <router-view />

    <v-bottom-sheet v-model="msgsheet">
      <v-sheet v-if="messageNum<1" class="text-center" height="200px">
        <v-btn class="mt-6" icon color="green" @click="msgsheet = !msgsheet">
            <v-icon>mdi-exit-to-app</v-icon>
        </v-btn>
        <div class="mt-6">Currently you have no active ReBells.</div>
      </v-sheet>

      <v-card v-else>
        <v-tabs v-model="tab" background-color="primary" dark>
          <v-tab v-for="item in messages" :key="item.bell.id + item.ringerid">
            {{ item.ringer.alias }} @{{ item.bell.name }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item v-for="item in messages"
          :key="item.bell.id + item.ringerid">
            <v-card flat>
<!--
    <v-list-item v-for="(msg) in item.ringer.messages" two-line dense
    :key="item.bell.id + item.ringerid+msg.correlation">
      <v-list-item-content>
        <v-list-item-title>{{msg.sender ? msg.sender : item.ringer.alias}}</v-list-item-title>
        <v-list-item-subtitle>{{msg.body}}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    -->
<!--
              <v-card-text>{{ JSON.stringify(item.ringer.messages) }}</v-card-text>
              <v-card-text>{{ item.ringer.alias }}</v-card-text>
-->
          <v-card-text v-for="(msg) in item.ringer.messages"
          style="padding-top: 2px; padding-bottom: 2px;"
          :key="item.bell.id + item.ringerid+msg.correlation">
           <span class="font-weight-medium">{{msg.sender ? msg.sender : item.ringer.alias}}:</span>
           {{msg.body}}
          </v-card-text>

          <v-container>
           <v-row class="mx-2">
             <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-message-reply-text"
                placeholder="Type reply here..."
                v-model="reply"
              />
            </v-col>
            <v-col cols="4">
              <v-btn outlined color='primary' :disabled="reply.length < 1"
               text @click="sendReply(item)">Send</v-btn>
            </v-col>
          </v-row>
        </v-container>

            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-bottom-sheet>

  </v-app>
</template>

<script>
// import VueLocalForage from 'vue-localforage';

const fb = require('../scripts/firebase_config');

export default {
  name: 'DashBoard',
  computed: {
    user() {
      return this.$store.state.user;
    },
    shares() {
      return this.$store.state.shares;
    },
    messageNum() {
      return this.$store.getters.messages;
    },
    messages() {
      return this.$store.state.messages;
    },
    /* devices() {
      return this.$store.state.devices;
    },
    bells() {
      return this.$store.state.bells;
    },
    rings() {
      return this.$store.state.rings;
    }, */
    /* messages() {
      const msgs = [];
      Object.entries(this.$store.state.messages).forEach((rebellid, ringers) => {
        Object.entries(ringers).forEach((ringerid, ringer) => {
          if (ringer.messages && ringer.messages.length > 0) {
            const bell = this.$store.getters.getBell(rebellid);
            msgs.push({
              bell,
              ringer,
              ringerid,
            });
          }
        });
      });
      return msgs;
    }, */
  },
  data() {
    return {
      drawer: true,
      msgsheet: false,
      tab: null,
      reply: '',
      // messages: [],
    };
  },
  methods: {
    sendReply(item) {
      const Replyer = fb.functions.httpsCallable('replyRing');
      const payload = {
        msg: this.reply,
        ringerid: item.ringerid,
        user: this.user.displayName,
      };
      Replyer(payload).then(() => {
        // Read result of the Cloud Function.

      }).catch((error) => {
        this.messages.length = 0;
        this.messages.push({
          type: 'error',
          message: `Error sending reply ${JSON.stringify(error)}`,
        });
      });
    },
    setter() {
      this.msgsheet = !this.msgsheet;
      console.log('tabs: ', JSON.stringify(this.messages));
    },
    signOut() {
      this.$emit('signOut');
    },
    home() {
      if (this.$route.path !== '/') this.$router.push('/');
    },
    /* refreshMessages() {
      console.log(`refresh messages ${JSON.stringify(this.$store.state.messages)}`);
      this.messages.length = 0;
      Object.entries(this.$store.state.messages).forEach((rebellid, ringers) => {
        console.log(`entry ${rebellid} - ${JSON.stringify(ringers)}`);
        Object.entries(ringers).forEach((ringerid, ringer) => {
          console.log(`ringer entry ${ringerid} - ${JSON.stringify(ringer)}`);
          if (ringer.messages && ringer.messages.length > 0) {
            const bell = this.$store.getters.getBell(rebellid);
            this.messages.push({
              bell,
              ringer,
              ringerid,
            });
          }
        });
      });
    },
    */
  },
  created() {
    /* eslint no-console: 0 */
    /* fb.messaging.onMessage((payload) => {
      console.log('FB Message received. ', payload);
      this.$store.commit('pushMessage', payload);
      console.log(`Messages: ${this.messageNum}`);
      console.log(`Messages: ${this.$store.getters.messages}`);
    }); */

    navigator.serviceWorker.addEventListener('message', (event) => {
      // firebase message - direct
      if (event.data.firebaseMessaging) {
        const { payload } = event.data.firebaseMessaging;

        this.$getItem(payload.data.rebell, (err, value) => {
          if (err) {
            console.log(`DB error: ${err}`);
            return;
          }
          let ringers = value;
          if (!value) {
            ringers = {};
          }
          if (!ringers[payload.data.ringer]) {
            ringers[payload.data.ringer] = {
              alias: payload.data.alias,
            };
          } else if (payload.data.alias) {
            ringers[payload.data.ringer].alias = payload.data.alias;
          }
          ringers[payload.data.ringer].time = Date.now();
          if (!ringers[payload.data.ringer].messages) {
            ringers[payload.data.ringer].messages = [];
          }
          ringers[payload.data.ringer].messages.push({
            correlation: payload.data.correlation,
            body: payload.data.body,
            read: false,
            sender: payload.data.sender,
          });

          this.$setItem(payload.data.rebell, ringers, () => {
            this.$store.commit('refreshMessages');
          //  this.refreshMessages();
          }).catch((seterr) => {
            // This code runs if there were any errors
            console.log(`DB error: ${seterr}`);
          });
        });

        // console.log('FB Message received. ', event.data.firebaseMessaging);
        // this.$store.commit('pushMessage', event.data.firebaseMessaging.payload);
        // console.log(`Messages: ${this.messageNum}`);
        // console.log(`Messages: ${this.$store.getters.messages}`);
      } else if (event.data) {
        // background message
        console.log('W Message received. ', event.data);
        // this.$store.commit('pushMessage', event.data);
        this.$store.commit('refreshMessages');
        // this.refreshMessages();
        console.log(`Messages: ${this.messageNum}`);
        console.log(`Messages: ${this.$store.getters.messages}`);
      }
    });

    /* this.$storageConfig({
      driver: VueLocalForage.INDEXEDDB,
      name: 'ReBell',
      version: 1.0,
      storeName: 'rings', // Should be alphanumeric, with underscores.
      description: 'Notifications received',
    });
    */

    // this.refreshMessages();
  },
};
</script>
<style scoped>
.v-tab {
  white-space: normal;
}
</style>
