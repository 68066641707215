import store from '../store/store';

const fb = require('./firebase_config');

function updateRings(snapshot) {
  store.commit('clearRings');
  store.commit('refreshRings', snapshot);
}

function refreshRings() {
  fb.getRings(store.state.user.uid, updateRings);
}

function updateDevices(snapshot) {
  store.commit('clearDevices');

  snapshot.forEach((doc) => {
    store.commit('pushDevice', {
      id: doc.id,
      name: doc.data().name,
      user: doc.data().user,
      token: doc.data().token,
      type: doc.data().type,
      description: doc.data().description,
    });
  });
  refreshRings();
  // store.commit('refreshMessages');
}

function refreshDevices() {
  if (store.state.user.uid) {
    fb.getDevices(store.state.user.uid, updateDevices);
  }
}


function updateBells(snapshot) {
  store.commit('clearMarkers');
  store.commit('clearBells');
  snapshot.forEach((doc) => {
    const item = {
      id: doc.id,
      name: doc.data().name,
      timestamp: doc.data().timestamp,
      location: doc.data().location,
      user: doc.data().user,
      description: doc.data().description,
      lock: doc.data().lock,
      welcome: doc.data().welcome,
      master: doc.data().master,
      owner: doc.data().owner,
    };
    store.commit('pushBell', item);
    store.commit('pushMarker', {
      position: {
        lat: item.location.latitude,
        lng: item.location.longitude,
      },
    });
  });
  fb.getRings(store.state.user.uid, updateRings);
}

function refreshBells() {
  if (store.state.user) {
    fb.getRebells(store.state.user.uid, updateBells);
  }
}

function updateShares(snapshot) {
  store.commit('refreshShares', snapshot);
}

function refreshShares() {
  if (store.state.user) {
    fb.getShares(store.state.user, updateShares);
  }
}

function iconForType(type) {
  switch (type) {
    case 'Browser':
      return 'mdi-laptop';
    case 'iOS':
      return 'mdi-cellphone-iphone';
    case 'Android':
      return 'mdi-android';
    default: return 'mdi-earth';
  }
}
export {
  refreshDevices,
  refreshBells,
  refreshRings,
  refreshShares,
  iconForType,
};
