<template>
    <v-content>
        <v-container fill-height fluid>
        <!-- van device meg rebell, de nincs ring -->
        <v-row v-if="devices.length > 0 && bells.length > 0 && norings"
            justify="center" align="center">
            <v-col cols="6" md="4" lg="3" v-for="(item, idx) in noring" :key="'nothing'+idx">
            <v-card raised dark :color="item.color">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">{{item.title}}</v-list-item-title>
                  <v-list-item-subtitle>{{item.text}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                    <v-icon v-text="item.icon" x-large></v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="item.to" outlined :to="item.to">{{item.btn}}</v-btn>
                <v-btn v-else text />
              </v-card-actions>
            </v-card>
            </v-col>
        </v-row>
        <!-- semmisenemvan -->
        <v-row v-else-if="(devices.length + bells.length)===0" justify="center" align="center">
            <v-col cols="6" md="4" lg="3" v-for="(item, idx) in nothing" :key="'nothing'+idx">
            <v-card raised dark :color="item.color">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">{{item.title}}</v-list-item-title>
                  <v-list-item-subtitle>{{item.text}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                    <v-icon v-text="item.icon" x-large></v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="item.to" outlined :to="item.to">{{item.btn}}</v-btn>
                <v-btn v-else text />
              </v-card-actions>
            </v-card>
            </v-col>
        </v-row>
        <!-- nincsen device -->
        <v-row v-else-if="devices.length===0" justify="center" align="center">
            <v-col cols="6" md="4" lg="3" v-for="(item, idx) in nodevice" :key="'nothing'+idx">
            <v-card raised dark :color="item.color">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">{{item.title}}</v-list-item-title>
                  <v-list-item-subtitle>{{item.text}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                    <v-icon v-text="item.icon" x-large></v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="item.to" outlined :to="item.to">{{item.btn}}</v-btn>
                <v-btn v-else text />
              </v-card-actions>
            </v-card>
            </v-col>
        </v-row>
        <!-- nincs rebell -->
        <v-row v-else-if="bells.length===0" justify="center" align="center">
            <v-col cols="6" md="4" lg="3" v-for="(item, idx) in nobell" :key="'nothing'+idx">
            <v-card raised dark :color="item.color">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">{{item.title}}</v-list-item-title>
                  <v-list-item-subtitle>{{item.text}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                    <v-icon v-text="item.icon" x-large></v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="item.to" outlined :to="item.to">{{item.btn}}</v-btn>
                <v-btn v-else text />
              </v-card-actions>
            </v-card>
            </v-col>
        </v-row>
        <!-- minden van -->
        <v-row v-else justify="center" align="center">
            <v-col cols="6" md="4" lg="3" v-for="(item, idx) in setup" :key="'nothing'+idx">
            <v-card raised dark :color="item.color">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">{{item.title}}</v-list-item-title>
                  <v-list-item-subtitle>{{item.text}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                    <v-icon v-text="item.icon" x-large></v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="item.to" outlined :to="item.to">{{item.btn}}</v-btn>
                <v-btn v-else text />
              </v-card-actions>
            </v-card>
            </v-col>
        </v-row>
        </v-container>
    </v-content>
</template>
<script>
export default {
  name: 'Home',
  computed: {
    devices() {
      return this.$store.state.devices;
    },
    bells() {
      return this.$store.state.bells;
    },
    rings() {
      return this.$store.state.rings;
    },
    norings() {
      return this.$store.getters.noRings;
    },
  },
  data() {
    return {
      drawer: true,
      nothing: [{
        title: '1 Add device',
        text: 'Register your device to receive notifications.',
        color: 'yellow darken-3',
        btn: 'Add',
        to: '/devices',
        icon: 'mdi-cellphone-wireless',
      }, {
        title: '2 Place a ReBell',
        text: 'Add a ReBell at a location where you want to place it.',
        color: 'lime darken-2',
        btn: 'Place',
        to: '/rebells',
        icon: 'mdi-bell',
      }, {
        title: '3 Post QR code',
        text: 'Print and post the generated QR code so people can ring you!',
        color: 'green darken-2',
        btn: null,
        to: null,
        icon: 'mdi-qrcode-scan',
      }],
      nodevice: [{
        title: '1 Add device',
        text: 'Register your device to receive notifications.',
        color: 'yellow darken-3',
        btn: 'Add',
        to: '/devices',
        icon: 'mdi-cellphone-wireless',
      }, {
        title: '2 ReBell placed',
        text: 'Congratulations, your first ReBell placed!.',
        color: 'lime darken-2',
        btn: 'Place',
        to: '/rebells',
        icon: 'mdi-check-bold',
      }, {
        title: '3 Post QR code',
        text: 'Print and post the generated QR code so people can ring you!',
        color: 'green darken-2',
        btn: null,
        to: null,
        icon: 'mdi-qrcode-scan',
      }],
      nobell: [{
        title: '1 Device added',
        text: 'Congratulations, you\'ve registered a device!',
        color: 'yellow darken-3',
        btn: 'Add',
        to: '/devices',
        icon: 'mdi-check-bold',
      }, {
        title: '2 Place a ReBell',
        text: 'Add a ReBell at a location where you want to place it.',
        color: 'lime darken-2',
        btn: 'Place',
        to: '/rebells',
        icon: 'mdi-bell',
      }, {
        title: '3 Post QR code',
        text: 'Print and post the generated QR code so people can ring you!',
        color: 'green darken-2',
        btn: null,
        to: null,
        icon: 'mdi-qrcode-scan',
      }],
      noring: [{
        title: 'Add notification',
        text: 'Open a ReBell and enable notifications!',
        color: 'yellow darken-3',
        btn: 'ReBells',
        to: '/rebells',
        icon: 'mdi-tune',
      }, {
        title: 'Post QR code',
        text: 'Print and post the generated QR code so people can ring you!',
        color: 'green darken-2',
        btn: 'ReBells',
        to: '/rebells',
        icon: 'mdi-qrcode-scan',
      }],
      setup: [{
        title: 'Devices',
        text: 'Manage devices.',
        color: 'yellow darken-3',
        btn: 'Devices',
        to: '/devices',
        icon: 'mdi-cellphone-wireless',
      }, {
        title: 'ReBells',
        text: 'Manage ReBells and configure notifications.',
        color: 'lime darken-2',
        btn: 'ReBells',
        to: '/rebells',
        icon: 'mdi-bell',
      }, {
        title: '3 Post QR code',
        text: 'Print and post the generated QR code so people can ring you!',
        color: 'green darken-2',
        btn: 'Print',
        to: '/rebells',
        icon: 'mdi-qrcode-scan',
      }],
    };
  },
};
</script>
