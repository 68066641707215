<template>
<v-content>
      <Messages :messages="messages" />
      <v-row v-if="shares.length > 0" justify="center" align="center">
         <v-col cols="6" md="4" lg="3" v-for="(item) in shares" :key="'share'+item.id">
            <v-card raised>
              <v-card-title>{{item.rebellName}}</v-card-title>
              <v-card-text>{{item.rebellDescription}}
                <div ><v-icon>mdi-account</v-icon>
                  {{item.username}}
                </div>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="accept(item)" color="primary" outlined>Accept</v-btn>
                <v-spacer></v-spacer>
                <v-btn @click="reject(item)" color="error" outlined>Reject</v-btn>
              </v-card-actions>
            </v-card>
         </v-col>
      </v-row>
      <v-container v-else fill-height fluid>
        <v-row justify="center" align="center">
            <v-col cols="6" md="4" lg="3" v-for="(item, idx) in noshares" :key="'noshares'+idx">
            <v-card raised dark :color="item.color">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">{{item.title}}</v-list-item-title>
                  <v-list-item-subtitle>{{item.text}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                    <v-icon v-text="item.icon" x-large></v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="item.to" outlined :to="item.to">{{item.btn}}</v-btn>
                <v-btn v-else text />
              </v-card-actions>
            </v-card>
            </v-col>
        </v-row>
      </v-container>
    </v-content>
</template>
<script>
import Messages from './Messages.vue';

const fb = require('../scripts/firebase_config');
const business = require('../scripts/business');

export default {
  name: 'Shares',
  components: {
    Messages,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    shares() {
      return this.$store.state.shares;
    },
    bells() {
      return this.$store.state.bells;
    },
  },
  data() {
    return {
      messages: [],
      noshares: [{
        title: 'No shares at the moment',
        text: 'Make sure that shares were sent to your email used to sign in.',
        color: 'green darken-2',
        btn: '',
        to: '',
        icon: 'mdi-share-variant',
      }],
    };
  },
  methods: {
    accept(share) {
      fb.rebellsCollection
        .add({
          user: this.user.uid,
          name: share.rebellName,
          lock: '',
          welcome: '',
          description: share.rebellDescription,
          location: {
            latitude: share.rebellLocation.latitude,
            longitude: share.rebellLocation.longitude,
          },
          master: share.rebell,
          owner: share.username,
          timestamp: fb.serverTimestamp(),
        }).then(() => {
          business.refreshBells();
          this.messages.push({
            type: 'success',
            message: `Successfully added ${JSON.stringify(share.rebellName)}`,
          });
          fb.sharesCollection
            .doc(share.id)
            .delete()
            .then(() => {
            })
            .catch(() => {
            })
            .finally(() => {
              business.refreshShares();
            });
        }).catch((error) => {
          this.messages.push({
            type: 'error',
            message: `Error saving ${JSON.stringify(share.rebellName)} ${JSON.stringify(error)}`,
          });
        });
    },
    reject(share) {
      fb.sharesCollection
        .doc(share.id)
        .delete()
        .then(() => {
          this.messages = [];
          this.messages.push({
            type: 'success',
            message: `Successfully rejected ${share.rebellName}`,
          });
        })
        .catch((error) => {
          this.messages = [];
          this.messages.push({
            type: 'error',
            message: `Error rejecting shared ${share.rebellName} ${JSON.stringify(error)}`,
          });
        })
        .finally(() => {
          business.refreshShares();
        });
    },
  },
  mounted() {
    business.refreshShares();
  },
};

</script>
