/* eslint-disable no-console */
import firebase from 'firebase';
import 'firebase/firestore';
import 'firebase/functions';
// firebase init goes here
const config = {
  apiKey: 'AIzaSyCFcZcGqqirBPdzlWIVA1jA_bP-fFUZmFc',
  authDomain: 'rebell-85346.firebaseapp.com',
  databaseURL: 'https://rebell-85346.firebaseio.com',
  projectId: 'rebell-85346',
  storageBucket: 'rebell-85346.appspot.com',
  messagingSenderId: '263844769628',
  appId: '1:263844769628:web:46a160573883613f2fdb99',
  measurementId: 'G-CHZVGXFN9Z',
};

firebase.initializeApp(config);

// firebase utils
const db = firebase.firestore();
const auth = firebase.auth();
const { currentUser } = auth;
const messaging = firebase.messaging();
const functions = firebase.functions();

// date issue fix according to firebase
const settings = {
//  timestampsInSnapshots: true,
};
db.settings(settings);

function serverTimestamp() {
  return firebase.firestore.FieldValue.serverTimestamp();
}
/*
function registerRebellQuery(user, changes) {
  const query = db.collection('rebells')
    .where('user', '==', user)
    .orderBy('timestamp', 'desc');
    // .limit(12);

  // Start listening to the query.
  return query.onSnapshot((snapshot) => {
    changes(snapshot.docChanges());
    // snapshot.docChanges().forEach((change) => {
    //  if (change.type === 'removed') {
        // deleteMessage(change.doc.id);
    //    removed(change);
    //  } else {
    //    modified(change);
        // var message = change.doc.data();
        // displayMessage(change.doc.id, message.timestamp, message.name,
        // message.location.latitude + ' - ' + message.location.longitude +' @ '+
        // message.user, message.profilePicUrl, message.imageUrl);
    //  }
    // });
  });
}
*/
function getRebells(user, process) {
  db.collection('rebells').where('user', '==', user).orderBy('name', 'asc').get()
    .then((snapshot) => {
      process(snapshot);
    })
    .catch((error) => {
      console.log('Error getting ReBells: ', error);
    });
}

function getDevices(user, process) {
  db.collection('devices').where('user', '==', user).orderBy('name', 'asc').get()
    .then((snapshot) => {
      process(snapshot);
    })
    .catch((error) => {
      console.log('Error getting devices: ', error);
    });
}

function getRings(user, process) {
  db.collection('rings').where('user', '==', user).get()
    .then((snapshot) => {
      process(snapshot);
    })
    .catch((error) => {
      console.log('Error getting rings: ', error);
    });
}

function getShares(user, process) {
  db.collection('shares').where('invited', '==', user.email).get()
    .then((snapshot) => {
      process(snapshot);
    })
    .catch((error) => {
      console.log('Error getting shares: ', error);
    });
}

// firebase collections
const rebellsCollection = db.collection('rebells');
const devicesCollection = db.collection('devices');
const ringsCollection = db.collection('rings');
const sharesCollection = db.collection('shares');

export {
  db,
  auth,
  messaging,
  functions,
  currentUser,
  rebellsCollection,
  devicesCollection,
  ringsCollection,
  sharesCollection,
  serverTimestamp,
  getRebells,
  getDevices,
  getRings,
  getShares,
};
