<template>
    <v-container>
        <v-row justify="center" align="start">
            <v-col cols="12" md="8" v-for="(msg, idx) in messages" :key="'msg' + idx">
                <v-alert :type="msg.type" dismissible outlined>{{msg.message}}</v-alert>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
  name: 'Messages',
  props: {
    messages: Array,
  },
  data() {
    return {};
  },
};
</script>
