import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
// import Vlf from 'vlf';
import VueLocalForage from 'vue-localforage';
// import VueQr from 'vue-qr';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import store from './store/store';


// const fb = require('./scripts/firebase_config');

Vue.config.productionTip = false;
// Vue.use(Vlf);

// Vue.component('vue-qr', VueQr)

Vue.use(VueLocalForage);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCFcZcGqqirBPdzlWIVA1jA_bP-fFUZmFc',
    libraries: 'places', // necessary for places input
  },
});

// const vue =
new Vue({
  router,
  vuetify,
  render: (h) => h(App),
  store,
  // components: { VueQr },
}).$mount('#app');
