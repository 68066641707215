<template>
  <v-app v-if="!user" id="inspire">
    <v-content >
      <v-container  class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>ReBell - Sign in</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <p class="font-weight-black">Welcome to ReBell, your revolutionary doorbell!</p>
                <p class="font-weight-bold">
                  You can learn more about it <a href="http://rebell.app">here</a>.<br/>
                  Or why not just start using it right away by signing in:</p>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn color="primary" v-on:click="signIn()">Continue with Google Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-content>
  </v-app>
  <DashBrd v-else v-on:signOut="signOut" />
</template>

<script>
import firebase from 'firebase';
import VueLocalForage from 'vue-localforage';
import DashBrd from './components/Dashbrd.vue';

const fb = require('./scripts/firebase_config');
const business = require('./scripts/business');

export default {
  name: 'App',
  components: {
    DashBrd,
  },
  data: () => ({
    dialog: null,
    drawer: null,
    source: null,
    unlisten: null,
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
    isNewDevice() {
      return this.$store.getters.isNewDevice;
    },
  },
  methods: {
    /* eslint no-console: 0 */
    // Saves the messaging device token to the datastore.
    saveMessagingDeviceToken() {
      fb.messaging.getToken().then((currentToken) => {
        if (currentToken) {
          console.log('Got FCM device token:', currentToken);
          this.$store.commit('setDeviceToken', {
            token: currentToken,
            comp: this,
          });
          // Saving the Device Token to the datastore.
        } else {
          // Need to request permissions to show notifications.
          this.requestNotificationsPermissions();
        }
      }).catch((error) => {
        console.error('Unable to get messaging token.', error);
      });
    },
    // Requests permission to show notifications.
    requestNotificationsPermissions() {
      console.log('Requesting notifications permission...');
      fb.messaging.requestPermission().then(() => {
        // Notification permission granted.
        this.saveMessagingDeviceToken();
      }).catch((error) => {
        console.error('Unable to get permission to notify.', error);
      });
    },
    // Signs-in ReBell Admin.
    signIn() {
      // Sign into Firebase using popup auth & Google as the identity provider.
      const provider = new firebase.auth.GoogleAuthProvider();
      fb.auth.signInWithPopup(provider).then(() => { // result
      }).catch((error) => {
        this.msg = JSON.stringify(error);
      });
    },
    signOut() {
      // Sign out of Firebase.
      fb.auth.signOut().then(() => {
        this.$store.commit('setUser', null);
      }).catch((error) => {
        this.msg = JSON.stringify(error);
      });
    },
  },
  created() {
    this.$storageConfig({
      driver: VueLocalForage.INDEXEDDB,
      name: 'ReBell',
      version: 1.0,
      storeName: 'rings', // Should be alphanumeric, with underscores.
      description: 'Notifications received',
    });
    this.$store.commit('initForage', {
      $getItem: this.$getItem,
      $setItem: this.$setItem,
      $iterateStorage: this.$iterateStorage,
    });
    this.$store.commit('setUser', fb.currentUser);
    console.log(`User: ${JSON.stringify(this.$store.state.user)}`);
    firebase.auth().onAuthStateChanged((user) => {
      this.$store.commit('setUser', user);
      console.log(`User changed: ${JSON.stringify(this.$store.state.user)}`);
      if (user) {
        console.log('refresh devices');
        business.refreshDevices();
        business.refreshBells();
        this.saveMessagingDeviceToken();
      }
    });
    this.unlisten = fb.messaging.onTokenRefresh((token) => {
      if (token && !this.isNewDevice()) {
        const FCMupdater = fb.functions.httpsCallable('updateFCMToken');
        const payload = {
          deviceid: this.$store.getters.thisNewDevice.id,
          token,
        };
        FCMupdater(payload).then(() => {
        // Read result of the Cloud Function.
        }).finally(() => {
          business.refreshDevices();
        });
      }
    });
  },
  destroyed() {
    this.unlisten();
  },
};
</script>
<style scoped>

#inspire {
  background-image: url('./assets/bckg_h.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-size: initial;
}
</style>
<style lang="scss">
.pulse {
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: inherit;
    border-radius: inherit;
    transition: opacity .3s, transform .3s;
    animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    z-index: -1;
  }

  // overflow: visible;
  // position: relative;
}

@keyframes pulse-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
</style>
