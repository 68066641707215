<template>
<v-content>
      <Messages :messages="messages" />

<v-dialog v-model="deviceDialog" width="500px">
      <v-card>
        <v-card-title v-if="adding" class="grey darken-2">
          Register this device
        </v-card-title>
        <v-card-title v-if="editing" class="grey darken-2">
          Update this device
        </v-card-title>
        <v-form v-model="validDevice">
        <v-container>
           <v-row class="mx-2">
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-cellphone-wireless"
                placeholder="Device name"
                label="Name"
                v-model="device.name"
                :rules="nameRules"
                required
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                prepend-icon="mdi-note-text-outline"
                placeholder="(Optional) Just a description for yourself"
                label="Description"
                v-model="device.description"
                :rules="descriptionRules"
              />
            </v-col>
          </v-row>
        </v-container>
        </v-form>
        <v-card-actions>
        <v-spacer />
        <v-btn text @click="cancel">Cancel</v-btn>
        <v-btn v-if="editing" text @click.prevent="updateDevice" color="primary"
        :disabled="!validDevice">Update</v-btn>
        <v-btn v-if="adding" text @click.prevent="addDevice" color="primary"
        :disabled="!validDevice">Register</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

      <v-row v-if="devices.length > 0" justify="center" align="center">
         <v-col cols="6" md="4" lg="3" v-for="(item) in devices" :key="'dev'+item.id">
            <v-card raised color='yellow darken-3'>
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline">{{item.type}}</div>
                  <v-list-item-title class="headline mb-1">{{item.name}}</v-list-item-title>
                  <v-list-item-subtitle>{{item.description}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                    <v-badge v-if="deviceToken==item.token" icon="mdi-account"
                    color="red" bordered>
                        <v-icon v-text="icon(item)" x-large></v-icon>
                    </v-badge>
                    <v-icon v-else v-text="icon(item)" x-large></v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-card-actions>
                <v-btn @click="beforeUpdate(item)" outlined>Edit</v-btn>
                <v-btn @click="deleteDevice(item)" outlined
                :loading="deleting[item.id]">Delete</v-btn>
                <v-spacer></v-spacer>
                <v-btn outlined :disabled="!isNewDevice || deviceType!==item.type || item.loading"
                @click="item.loading=true; refreshFCM(item)" :loading="item.loading">Reset</v-btn>
              </v-card-actions>
            </v-card>
         </v-col>
         <v-btn bottom color="pink" dark fab fixed right :disabled="!isNewDevice"
         @click="adding = true; deviceDialog=true; editing = false;" > <v-icon>mdi-plus</v-icon>
        </v-btn>
       </v-row>
       <v-container v-else fill-height fluid>
            <v-row justify="center" align="center">
            <v-col cols="6" md="4" lg="3" v-for="(item, idx) in hint" :key="'hint'+idx">
            <v-card raised dark :color="item.color">
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="headline mb-1">{{item.title}}</v-list-item-title>
                  <v-list-item-subtitle>{{item.text}}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon>
                    <v-icon v-text="item.icon" x-large></v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-spacer></v-spacer>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn v-if="item.to" outlined :to="item.to">{{item.btn}}</v-btn>
                <v-btn v-else text />
              </v-card-actions>
            </v-card>
            </v-col>
            <v-tooltip left color="pink lighten-2" v-model="tooltip">
              <template v-slot:activator="{ on }">
                 <v-btn v-on="on" color="pink" bottom dark fab fixed right class="pulse" x-large
                  @click="adding = true; deviceDialog = true; editing = false;" >
                    <v-icon x-large>mdi-cellphone-wireless</v-icon>
                 </v-btn>
              </template>
              <span>
                Add this device <v-icon color="white">mdi-arrow-right-bold-outline</v-icon>
              </span>
            </v-tooltip>
        </v-row>
       </v-container>
</v-content>
</template>

<script>
import Messages from './Messages.vue';

const fb = require('../scripts/firebase_config');
const business = require('../scripts/business');

export default {
  name: 'Devices',
  components: {
    Messages,
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    deviceToken() {
      return this.$store.state.deviceToken;
    },
    devices() {
      return this.$store.state.devices;
    },
    isNewDevice() {
      return this.$store.getters.isNewDevice;
    },
  },
  data() {
    return {
      deviceType: 'Browser',
      tooltip: true,
      deleting: {},
      editing: false,
      adding: false,
      deviceDialog: false,
      validDevice: false,
      nameRules: [
        (v) => !!v || 'Name is required',
        (v) => v.length <= 15 || 'Name must be less than 15 characters',
      ],
      descriptionRules: [
        (v) => v.length <= 50 || 'Description must be less than 50 characters',
      ],
      device: {
        id: null,
        name: '',
        description: '',
      },
      messages: [],
      hint: [{
        title: 'Add device',
        text: 'Register your device to receive notifications.',
        color: 'yellow darken-3',
        btn: null,
        to: null,
        icon: 'mdi-cellphone-wireless',
      }, {
        title: 'Set notifications',
        text: 'In the ReBells settings control notifications',
        color: 'green darken-2',
        btn: null,
        to: null,
        icon: 'mdi-tune',
      }],
    };
  },
  methods: {
    icon(device) {
      return business.iconForType(device.type);
    },
    cancel() {
      this.editing = false;
      this.adding = false;
      this.deviceDialog = false;
      this.device.name = '';
      this.device.description = '';
      this.device.id = null;
    },
    beforeUpdate(device) {
      this.device.name = device.name;
      this.device.description = device.description;
      this.device.id = device.id;
      this.editing = true;
      this.adding = false;
      this.deviceDialog = true;
    },
    refreshFCM(device) {
      const { name } = device;
      const FCMupdater = fb.functions.httpsCallable('updateFCMToken');
      const payload = {
        deviceid: device.id,
        token: this.deviceToken,
      };
      FCMupdater(payload).then(() => {
        // Read result of the Cloud Function.
        this.messages.length = 0;
        this.messages.push({
          type: 'success',
          message: `Successfully updated device FCM ${JSON.stringify(name)}`,
        });
      }).catch((error) => {
        this.messages.length = 0;
        this.messages.push({
          type: 'error',
          message: `Error updating FCM for ${JSON.stringify(name)} ${JSON.stringify(error)}`,
        });
      }).finally(() => {
        business.refreshDevices();
      });
    },
    updateDevice() {
      const { name } = this.device;
      fb.devicesCollection.doc(this.device.id).update({
        name: this.device.name,
        description: this.device.description,
      }).then(() => {
        business.refreshDevices();
        this.messages.length = 0;
        this.messages.push({
          type: 'success',
          message: `Successfully updated device ${JSON.stringify(name)}`,
        });
      }).catch((error) => {
        this.messages.length = 0;
        this.messages.push({
          type: 'error',
          message: `Error updating ${JSON.stringify(name)} ${JSON.stringify(error)}`,
        });
      });
      this.cancel();
    },
    addDevice() {
      const { name } = this.device;
      fb.devicesCollection.add({
        name: this.device.name,
        description: this.device.description,
        user: this.user.uid,
        token: this.deviceToken,
        type: this.deviceType,
      }).then(() => {
        business.refreshDevices();
        this.messages.length = 0;
        this.messages.push({
          type: 'success',
          message: `Successfully registered ${JSON.stringify(name)}`,
        });
      }).catch((error) => {
        this.messages.length = 0;
        this.messages.push({
          type: 'error',
          message: `Error registering ${JSON.stringify(name)} ${JSON.stringify(error)}`,
        });
      });
      this.cancel();
    },
    deleteDevice(device) {
      const deviceDeleter = fb.functions.httpsCallable('deleteDevice');
      const payload = {
        deviceid: device.id,
      };
      this.deleting[device.id] = true;

      deviceDeleter(payload).then((result) => {
        // Read result of the Cloud Function.
        const response = result.data;
        if (response.status === 'ok') {
          this.messages.length = 0;
          this.messages.push({
            type: 'success',
            message: `Successfully deleted ${device.name} removing ${response.rings} listeners.`,
          });
        } else {
          this.messages.length = 0;
          this.messages.push({
            type: 'error',
            message: `Problem deleting ${device.name} removed ${response.rings} listeners, failed: ${response.ring_errors}. Status: ${response.status}`,
          });
        }
      }).catch((error) => {
        const errormsg = error.message;
        const { status } = error;
        this.messages.length = 0;
        this.messages.push({
          type: 'error',
          message: `Error deleting ${device.name} ${errormsg} ${status}`,
        });
      }).finally(() => {
        business.refreshDevices();
        delete this.deleting[device.id];
      });
    },
  },
};
</script>
