import Vuex from 'vuex';
import Vue from 'vue';

Vue.use(Vuex);

// const fb = require('../scripts/firebase_config');
function getThisDevice(state) {
  return state.devices.filter((device) => device.token === state.deviceToken)[0];
}

function getBell(state, id) {
  return state.bells.find((bell) => bell.id === id);
}

function updateMessages(state) {
  // Object.keys(state.messages).forEach((key) => {
  //  delete state.messages[key];
  // });
  console.log(`store token:${state.deviceToken} - ${JSON.stringify(state.devices)}`);
  const device = getThisDevice(state);
  console.log(`device: ${JSON.stringify(device)}`);
  if (!device) return;

  console.log(`rings: ${JSON.stringify(state.rings)}`);
  console.log('iterate storage');
  const newMessages = [];
  state.forage.$iterateStorage((ringers, rebellid) => {
    console.log(`key ${rebellid} - ${JSON.stringify(ringers)}`);
    // if(state.bells.some((bell) => bell.id === key)){
    // our bell and enabled
    console.log(`set ${state.rings[rebellid] && state.rings[rebellid][device.id]
      && state.rings[rebellid][device.id].switch}`);
    if (state.rings[rebellid] && state.rings[rebellid][device.id]
      && state.rings[rebellid][device.id].switch) {
      // Vue.set(state.messages, rebellid, ringers);

      Object.entries(ringers).forEach((entry) => {
        const ringerid = entry[0];
        const ringer = entry[1];
        // console.log(`ringer entry ${ringerid} - ${ringerStr}`);
        // const ringer = JSON.parse(ringerStr);
        console.log(`ringer entry ${ringerid} - ${JSON.stringify(ringer)}`);
        if (ringer.messages && ringer.messages.length > 0
          && ringerid !== '_Landlord') {
          const bell = getBell(state, rebellid);
          console.log(`bell: ${JSON.stringify(bell)}`);
          newMessages.push({
            bell,
            ringer,
            ringerid,
          });
        }
      });
    }
  }, (err) => {
    if (err) {
      /* eslint no-console: 0 */
      console.log(err);
    }
  }).then(() => {
    state.messages.length = 0;
    newMessages.forEach((msg) => {
      state.messages.push(msg);
    });
  });
}

export default new Vuex.Store({
  state: {
    rings: {}, // user's devices enabled for bells
    devices: [], // user's devices
    bells: [], // user's bells
    markers: [], // google map markers
    shares: [], // invites received
    user: null, // logged in user
    center: null, // gooogle map centered location
    deviceToken: null, // current device FCM token
    messages: [], // messages bellid -> ringerid -> messages
    forage: null,
  },

  getters: {
    isNewDevice: (state) => !state.devices.some((device) => device.token === state.deviceToken),
    // eslint-disable-next-line max-len
    // thisDevice: (state) => state.devices.filter((device) => device.token === state.deviceToken)[0],
    thisDevice: (state) => getThisDevice(state),
    // eslint-disable-next-line max-len
    noRings: (state) => !Object.values(state.rings).some((bell) => Object.values(bell).some((dev) => dev.switch)),
    // eslint-disable-next-line max-len
    // messages: (state) => Object.entries(state.messages).reduce((total, [, value]) => total + 1, 0),
    // messages: (state) => Object.entries(state.messages).length,
    messages: (state) => state.messages.length,
    getBell: (state, id) => getBell(state, id),
  },
  mutations: {
    // user
    setUser(state, user) {
      state.user = user;
    },
    // device token
    setDeviceToken(state, payload) {
      state.deviceToken = payload.token;
      updateMessages(state, payload.comp);
    },
    // devices
    clearDevices(state) {
      state.devices.splice(0, state.devices.length);
    },
    pushDevice(state, device) {
      state.devices.push(device);
      console.log(`devices: ${JSON.stringify(state.devices)}`);
    },
    // markers
    clearMarkers(state) {
      state.markers.splice(0, state.markers.length);
    },
    pushMarker(state, marker) {
      state.markers.push(marker);
    },
    // bells
    clearBells(state) {
      state.bells.splice(0, state.bells.length);
    },
    pushBell(state, bell) {
      state.bells.push(bell);
      if (!state.center) {
        state.center = {
          lat: bell.location.latitude,
          lng: bell.location.longitude,
        };
      }
    },
    // center
    setCenter(state, center) {
      state.center = center;
    },
    // rings
    clearRings(state) {
      Object.keys(state.rings).forEach((key) => {
        delete state.rings[key];
      });
    },
    refreshRings(state, snapshot) {
      state.bells.forEach((bell) => {
        state.rings[bell.master ? bell.master : bell.id] = {};
        state.devices.forEach((dev) => {
          state.rings[bell.master ? bell.master : bell.id][dev.id] = {};
        });
      });
      snapshot.forEach((doc) => {
        state.rings[doc.data().rebell][doc.data().device] = {
          id: doc.id,
          token: doc.data().token,
          switch: true,
        };
      });
      updateMessages(state);
    },
    // shares
    refreshShares(state, snapshot) {
      state.shares = [];
      snapshot.forEach((doc) => {
        state.shares.push({
          id: doc.id,
          invited: doc.data().invited,
          rebell: doc.data().rebell,
          user: doc.data().user,
          username: doc.data().username,
          rebellName: doc.data().rebellName,
          rebellDescription: doc.data().rebellDescription,
          rebellLocation: doc.data().rebellLocation,
        });
      });
    },
    refreshMessages(state) {
      updateMessages(state);
    },
    // indexed db
    initForage(state, forage) {
      state.forage = forage;
    },
  },

  actions: {
    setUser(context, user) {
      context.commit('setUser', user);
    },

  },
});
